import { Dictionary } from '@reduxjs/toolkit';
import { IPortalPageElement } from 'api/digifi/PortalPageElementsApi';
import {
  IButtonPageElement,
  IESignatureElement,
  IOfferSelectionPageElement,
  PortalPageElementType,
} from 'api/digifi/portal-page-elements';
import { ITestModeNotificationItems } from 'components/TestModeNotificationList/TestModeNotificationList';
import { IAutomationWorkflowStateModel } from 'handlers/automationWorkflowSlice';
import { useAppSelector } from 'hooks/reduxHooks';
import { TableVisualAttributes, Variable, VariableDataType } from 'product_modules/api/Core/VariablesApi';
import { useGetDocuSignIntegrationDataQuery } from 'slices/docuSignSlice';

const validateOffersVariable = (
  elements: Array<IPortalPageElement | undefined>,
  variables: Dictionary<Variable>,
): ITestModeNotificationItems | null => {
  const offersElement = (elements
    .find((element) => element?.elementType === PortalPageElementType.OfferSelection) || null) as IOfferSelectionPageElement | null;

  const offersVariable = Object.values(variables)
    .find((variable) => variable?.systemName === offersElement?.config.variable);

  const offersVariableDisplayAttributes = offersElement
    ? (offersVariable?.visualAttributes || null) as TableVisualAttributes || null
    : null;

  const validSystemColumns = offersElement && offersVariableDisplayAttributes?.columns.filter((column) => {
    return column.dataType === VariableDataType.Boolean && (
      column.systemName === offersElement.config.availableColumn || column.systemName === offersElement.config.selectedColumn
    );
  });

  const displayedColumns = offersVariableDisplayAttributes && offersElement?.config.displayedColumns.filter((column) => {
    return offersVariableDisplayAttributes.columns.find(({ systemName }) => systemName === column);
  });

  const isSystemColumnsConfigValid = !validSystemColumns || validSystemColumns.length === 2;
  const isDisplayedColumnsConfigValid = !displayedColumns || displayedColumns.length > 0;

  // both system and displayed columns config are invalid
  if (!isSystemColumnsConfigValid && !isDisplayedColumnsConfigValid) {
    return {
      title: 'Offers Display Issue',
      message: 'Offers are not displayed due to an issue with offer setup. Ensure the Available and Selected columns have boolean type and have not been deleted. Additionally, make sure the Displayed Columns have not been removed.',
    };
  }

  // only system column config is invalid
  if (!isSystemColumnsConfigValid && isDisplayedColumnsConfigValid) {
    return {
      title: 'Offers Display Issue',
      message: 'Offers are not displayed due to an issue with offer setup. Ensure Available and Selected columns have boolean type and have not been deleted.',
    };
  }

  // only displayed column config is invalid
  if (isSystemColumnsConfigValid && !isDisplayedColumnsConfigValid) {
    return {
      title: 'Offers Display Issue',
      message: 'Offers are not displayed due to an issue with offer setup. Ensure the Displayed Columns have not been removed.',
    };
  }

  return null;
};

const validateButtonElement = (
  buttonElement: IButtonPageElement | null,
  automationWorkflow: IAutomationWorkflowStateModel | null | undefined,
): ITestModeNotificationItems | null => {
  if (buttonElement && (automationWorkflow === null || automationWorkflow?.isDeleted)) {
    return {
      title: 'Button Missing Automation Workflow',
      message: `The ${buttonElement.config.text} button is currently not linked to any automation workflow. Ensure to connect an automation to enable button functionality.`,
    };
  }

  return null;
};

const validateESignatureElement = (
  eSignatureElement: IESignatureElement | null,
  isIntegrationSetUp: boolean,
) => {
  return (eSignatureElement && !isIntegrationSetUp) ? {
    title: 'E-Signature Disconnected ',
    message: 'The E-Signature element is currently not functioning. Ensure that the Docusign integration is connected.',
  } : null;
};

const findElementByType = <Type extends IPortalPageElement>(
  elements: Dictionary<IPortalPageElement>,
  elementType: PortalPageElementType,
): Type | null => {
  return (Object.values(elements).find((element) => element?.elementType === elementType) || null) as Type | null;
};

const useTestModeNotifications = (applicationId?: string | null): ITestModeNotificationItems[] => {
  const application = useAppSelector((state) => applicationId ? state.applications.entities[applicationId] : null);
  const { entities, isLoaded } = useAppSelector((state) => state.portalPageElements);
  const variables = useAppSelector((state) => state.variables.entities);

  const buttonElement = findElementByType<IButtonPageElement>(entities, PortalPageElementType.Button);
  const eSignatureElement = findElementByType<IESignatureElement>(entities, PortalPageElementType.ESignature);

  const {
    currentData: docuSignIntegrationData,
    isLoading: isDocuSignIntegrationDataLoading,
    error: docuSignIntegrationDataError,
  } = useGetDocuSignIntegrationDataQuery(application?.displayId || '', {
    skip: !eSignatureElement || !application,
  });

  const automationWorkflow = useAppSelector((state) => (
    buttonElement?.config.automationWorkflowId
      ? state.automationWorkflows.entities[buttonElement.config.automationWorkflowId]
      : null
  ));

  const elements = Object.values(entities);

  if (!application || !isLoaded) {
    return [];
  }

  if (elements.length === 0) {
    return [{
      title: 'Unavailable Page',
      message: `There is no digital lending portal page set up for the workflow status named ${application.status.name}. To fix this issue, map a portal page to this workflow status within the DigiFi platform.`,
    }];
  }

  const isESignatureIntegrationSetUp = isDocuSignIntegrationDataLoading
    || !!docuSignIntegrationDataError
    || !!docuSignIntegrationData;

  const errors = [
    validateOffersVariable(elements, variables),
    validateButtonElement(buttonElement, automationWorkflow),
    validateESignatureElement(eSignatureElement, isESignatureIntegrationSetUp),
  ];

  return errors.filter((error) => error) as ITestModeNotificationItems[];
};

export default useTestModeNotifications;
